"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250402141554/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
// svg component

// register globally
_vue.default.component('svg-icon', _SvgIcon.default);
var req = require.context('./svg', false, /\.svg$/);
var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};
requireAll(req);