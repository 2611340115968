"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
require("./app.scss");
//
//
//
//
//
//
// global css
var _default = exports.default = {
  name: 'App',
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isRouterAlive: true,
      beforeUnload_time: null,
      gap_time: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    // 监听浏览器关闭
    window.addEventListener('beforeunload', function (e) {
      return _this.beforeunloadHandler(e);
    });
    window.addEventListener('unload', function (e) {
      return _this.unloadHandler(e);
    });
  },
  created: function created() {
    var _this2 = this;
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', function () {
      sessionStorage.setItem('store', JSON.stringify(_this2.$store.state));
    });
    this.$store.dispatch('app/setCompanyname'); // 1:瀚星 2:瀚马 3:瀚宝 4:瀚兴
    this.$store.dispatch('user/setCertificationFlag'); // 行业身份
  },
  methods: {
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 监听浏览器关闭
    beforeunloadHandler: function beforeunloadHandler() {
      this.beforeUnload_time = new Date().getTime();
    },
    unloadHandler: function unloadHandler(e) {
      this.gap_time = new Date().getTime() - this.beforeUnload_time;
      // 判断是窗口关闭还是刷新
      if (this.gap_time <= 5) {
        // 如果是登录状态，关闭窗口前，removeToken
        (0, _auth.removeToken)();
      }
    }
  }
};