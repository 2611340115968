var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [_c("span", [_vm._v("个人信息")])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "user-profile" }, [
        _c(
          "div",
          { staticClass: "box-center" },
          [
            _c("el-image", {
              staticStyle: { width: "100px", height: "100px" },
              attrs: { src: _vm.user.avatar, fit: "fit" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "box-center" }, [
          _c("div", { staticClass: "user-name text-center" }, [
            _vm._v(_vm._s(_vm.user.name)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user-bio" }, [
        _c(
          "div",
          { staticClass: "user-skills user-bio-section" },
          [
            _c("el-divider", { attrs: { "content-position": "left" } }),
            _vm._v(" "),
            _c("div", { staticClass: "user-bio-section-body" }, [
              _c("div", { staticClass: "progress-item" }, [
                _c("span", [_vm._v("登录账户:")]),
                _vm._v(" "),
                _c("span", { staticClass: "progress-item-span" }, [
                  _vm._v(_vm._s(_vm.user.userName)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress-item" }, [
                _c("span", [_vm._v("电子邮箱:")]),
                _vm._v(" "),
                _c("span", { staticClass: "progress-item-span" }, [
                  _vm._v(_vm._s(_vm.user.email)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress-item" }, [
                _c("span", [_vm._v("手机号码:")]),
                _vm._v(" "),
                _c("span", { staticClass: "progress-item-span" }, [
                  _vm._v(_vm._s(_vm.user.mobile)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "progress-item" },
                [
                  _c("span", [_vm._v("企业微信:")]),
                  _vm._v(" "),
                  _vm.userInfos.cpUserId === ""
                    ? _c(
                        "el-tag",
                        {
                          staticClass: "progress-item-span",
                          staticStyle: { cursor: "pointer" },
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.openCode()
                            },
                          },
                        },
                        [_vm._v("点击绑定")]
                      )
                    : _c(
                        "el-tag",
                        {
                          staticClass: "progress-item-span",
                          staticStyle: { cursor: "pointer" },
                          attrs: { type: "success" },
                        },
                        [_vm._v("已绑定")]
                      ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  color: "rgb(90, 90, 90)",
                  "font-size": "22px",
                  "font-weight": "bold",
                },
              },
              [_vm._v("请使用企业微信扫码")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "5px",
                  "font-size": "16px",
                  "margin-bottom": "20px",
                },
              },
              [
                _vm._v("请于【企业微信】 - 【消息】 右上角\n        "),
                _c("i", {
                  staticClass: "el-icon-circle-plus-outline",
                  staticStyle: {
                    color: "rgb(77, 161, 255)",
                    "font-size": "22px",
                  },
                }),
                _vm._v(" 选择【扫一扫】进行扫码\n      "),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [
              _c("vue-qr", {
                attrs: { "logo-src": _vm.imageUrl, text: _vm.codes, size: 200 },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }