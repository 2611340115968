"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250402141554/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/lang/index");
var _pubilc = require("@/api/system/pubilc");
var state = {
  sidebar: {
    // opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    opened: true,
    withoutAnimation: true
  },
  device: 'desktop',
  language: (0, _index.getLanguage)(),
  size: _jsCookie.default.get('size') || 'medium',
  district: [],
  userSex: [],
  salesUnit: [],
  productTag: [],
  companyCategory: [],
  companyking: [],
  userInfoCertificationType: [],
  productMngSelfOperatedStatus: [],
  purchaseOrderSupplierPayment: [],
  purchaseOrderStatus: [],
  purchaseSource: [],
  purchaseLessStatus: [],
  purchasePlanGoodsStatus: [],
  storeType: [],
  storeRecord: [],
  storeRecordType: [],
  storeRecordTips: [],
  storeRecordClassify: [],
  category: [],
  logistics: [],
  companyName: [],
  csrf: {}
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    _jsCookie.default.set('language', language);
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    _jsCookie.default.set('size', size);
  },
  SET_CATEGORY: function SET_CATEGORY(state, category) {
    state.category = category;
  },
  SET_LOGISTICS: function SET_LOGISTICS(state, logistics) {
    state.logistics = logistics;
  },
  SET_DISTRICT: function SET_DISTRICT(state, district) {
    state.district = district;
  },
  SET_USERSEX: function SET_USERSEX(state, userSex) {
    state.userSex = userSex;
  },
  SET_SALESUNIT: function SET_SALESUNIT(state, salesUnit) {
    state.salesUnit = salesUnit;
  },
  SET_PRODUCTTAG: function SET_PRODUCTTAG(state, productTag) {
    state.productTag = productTag;
  },
  SET_COMPANYCATEGORY: function SET_COMPANYCATEGORY(state, companyCategory) {
    state.companyCategory = companyCategory;
  },
  SET_COMPANYKING: function SET_COMPANYKING(state, companyking) {
    state.companyking = companyking;
  },
  SET_USERINFOCERTIFICATIONTYPE: function SET_USERINFOCERTIFICATIONTYPE(state, userInfoCertificationType) {
    state.userInfoCertificationType = userInfoCertificationType;
  },
  SET_PRODUCTMNGSELFOPERATEDSTATUS: function SET_PRODUCTMNGSELFOPERATEDSTATUS(state, productMngSelfOperatedStatus) {
    state.productMngSelfOperatedStatus = productMngSelfOperatedStatus;
  },
  SET_PURCHASEORDERSUPPLIERPAYMENT: function SET_PURCHASEORDERSUPPLIERPAYMENT(state, purchaseOrderSupplierPayment) {
    state.purchaseOrderSupplierPayment = purchaseOrderSupplierPayment;
  },
  SET_PURCHASEORDERSTATUS: function SET_PURCHASEORDERSTATUS(state, purchaseOrderStatus) {
    state.purchaseOrderStatus = purchaseOrderStatus;
  },
  SET_PURCHASESOURCE: function SET_PURCHASESOURCE(state, purchaseSource) {
    state.purchaseSource = purchaseSource;
  },
  SET_PURCHASELESSSTATUS: function SET_PURCHASELESSSTATUS(state, purchaseLessStatus) {
    state.purchaseLessStatus = purchaseLessStatus;
  },
  SET_PURCHASEPLANGOODSSTATUS: function SET_PURCHASEPLANGOODSSTATUS(state, purchasePlanGoodsStatus) {
    state.purchasePlanGoodsStatus = purchasePlanGoodsStatus;
  },
  SET_STORETYPE: function SET_STORETYPE(state, storeType) {
    state.storeType = storeType;
  },
  SET_STORERECORD: function SET_STORERECORD(state, storeRecord) {
    state.storeRecord = storeRecord;
  },
  SET_STORERECORDTYPE: function SET_STORERECORDTYPE(state, storeRecordType) {
    state.storeRecordType = storeRecordType;
  },
  SET_STORERECORDTIPS: function SET_STORERECORDTIPS(state, storeRecordTips) {
    state.storeRecordTips = storeRecordTips;
  },
  SET_STORERECORDCLASSIFY: function SET_STORERECORDCLASSIFY(state, storeRecordClassify) {
    state.storeRecordClassify = storeRecordClassify;
  },
  SET_COMPANYNAME: function SET_COMPANYNAME(state, companyName) {
    state.companyName = companyName;
  },
  SET_CSRF: function SET_CSRF(state, csrf) {
    state.csrf = csrf;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setLanguage: function setLanguage(_ref5, language) {
    var commit = _ref5.commit;
    commit('SET_LANGUAGE', language);
  },
  setLogistics: function setLogistics(_ref6, logistics) {
    var commit = _ref6.commit;
    logistics = [{
      name: '顺丰',
      id: 'SF'
    }, {
      name: '京东物流',
      id: 'JD'
    }, {
      name: '中通',
      id: 'ZTO'
    }, {
      name: '申通',
      id: 'STO'
    }, {
      name: '圆通',
      id: 'YTO'
    }, {
      name: '韵达',
      id: 'YD'
    }, {
      name: '韵达快运',
      id: 'YUNDA56'
    }, {
      name: '安能物流',
      id: 'ANE'
    }, {
      name: '速通物流',
      id: 'ST'
    }, {
      name: '壹米滴答',
      id: 'YIMIDIDA'
    }, {
      name: '跨越速运',
      id: 'KYSY'
    }, {
      name: '德邦物流',
      id: 'DBL'
    }, {
      name: '邮政平邮',
      id: 'YZPY'
    }, {
      name: 'EMS',
      id: 'EMS'
    }, {
      name: '百世',
      id: 'HTKY'
    }, {
      name: '天天',
      id: 'HHTT'
    }, {
      name: '宅急送',
      id: 'ZJS'
    }, {
      name: '优速快递',
      id: 'UC'
    }];
    commit('SET_LOGISTICS', logistics);
  },
  setCompanyname: function setCompanyname(_ref7, companyname) {
    var commit = _ref7.commit;
    companyname = [{
      name: '南京瀚星',
      id: 1
    }, {
      name: '南京瀚马',
      id: 2
    }, {
      name: '上海瀚宝',
      id: 3
    }, {
      name: '重庆瀚兴',
      id: 4
    }, {
      name: '南京瀚林',
      id: 5
    }];
    commit('SET_COMPANYNAME', companyname);
  },
  setSize: function setSize(_ref8, size) {
    var commit = _ref8.commit;
    commit('SET_SIZE', size);
  },
  setStoreRecord: function setStoreRecord(_ref9) {
    var commit = _ref9.commit;
    return new Promise(function (resolve, reject) {
      (0, _pubilc.storeRecord)().then(function (response) {
        commit('SET_STORERECORD', response.data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setCsrf: function setCsrf(_ref10) {
    var commit = _ref10.commit;
    return new Promise(function (resolve, reject) {
      var len = len || 20;
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      var maxPos = chars.length;
      var pwd = '';
      for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      (0, _pubilc.CSRF)(pwd).then(function (res) {
        var parms = {
          sessionid: pwd,
          subcode: res.data
        };
        commit('SET_CSRF', parms);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  resetCsrf: function resetCsrf(_ref11) {
    var commit = _ref11.commit;
    return new Promise(function (resolve) {
      commit('SET_CSRF', null);
      resolve();
    });
  },
  setProductTag: function setProductTag(_ref12) {
    var commit = _ref12.commit;
    return new Promise(function (resolve, reject) {
      (0, _pubilc.tags)('').then(function (response) {
        commit('SET_PRODUCTTAG', response.data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setcategory: function setcategory(_ref13) {
    var commit = _ref13.commit;
    return new Promise(function (resolve, reject) {
      (0, _pubilc.category)().then(function (response) {
        commit('SET_CATEGORY', response.data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setdistrict: function setdistrict(_ref14) {
    var commit = _ref14.commit;
    return new Promise(function (resolve, reject) {
      (0, _pubilc.district)().then(function (response) {
        commit('SET_DISTRICT', response.data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  typeList: function typeList(_ref15, dictType) {
    var commit = _ref15.commit;
    return new Promise(function (resolve, reject) {
      (0, _pubilc.typeList)(dictType).then(function (response) {
        switch (dictType) {
          case 'sys_user_sex':
            commit('SET_USERSEX', response.data);
            break;
          case 'sys_sales_unit':
            commit('SET_SALESUNIT', response.data);
            break;
          // case 'product_tag':
          //   commit('SET_PRODUCTTAG', response.data)
          //   break
          case 'company_category':
            commit('SET_COMPANYCATEGORY', response.data);
            break;
          case 'company_king':
            commit('SET_COMPANYKING', response.data);
            break;
          case 'user_info_certification_type':
            commit('SET_USERINFOCERTIFICATIONTYPE', response.data);
            break;
          case 'product_mng_self_operated_status':
            commit('SET_PRODUCTMNGSELFOPERATEDSTATUS', response.data);
            break;
          case 'purchase_order_supplier_payment':
            commit('SET_PURCHASEORDERSUPPLIERPAYMENT', response.data);
            break;
          case 'purchase_order_status':
            commit('SET_PURCHASEORDERSTATUS', response.data);
            break;
          case 'purchase_source':
            commit('SET_PURCHASESOURCE', response.data);
            break;
          case 'purchase_less_status':
            commit('SET_PURCHASELESSSTATUS', response.data);
            break;
          case 'purchase_plan_goods_status':
            commit('SET_PURCHASEPLANGOODSSTATUS', response.data);
            break;
          case 'store_type':
            commit('SET_STORETYPE', response.data);
            break;
          case 'store_record_type':
            commit('SET_STORERECORDTYPE', response.data);
            break;
          case 'store_record_tips':
            commit('SET_STORERECORDTIPS', response.data);
            break;
          case 'store_record_classify':
            commit('SET_STORERECORDCLASSIFY', response.data);
            break;
        }
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};