var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c("div", { staticClass: "left-logo" }, [
      _c("img", { attrs: { src: require("../../img/logo.png"), alt: "" } }),
      _vm._v(" "),
      _vm.versions === "pro"
        ? _c("div", { staticClass: "left-msg" }, [
            _vm._v("兽医首选 · Vets 云仓业务运营支撑系统\n          "),
            _c(
              "span",
              { staticStyle: { "font-size": "16px", color: "#999" } },
              [
                _vm._v(
                  "Vets Smart Choice · Business & Operation Support\n        System"
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.versions === "dev"
        ? _c(
            "div",
            {
              staticClass: "left-msg flex",
              staticStyle: { display: "flex", "flex-direction": "row" },
            },
            [
              _vm._v("兽医首选 · Vets\n      云仓业务运营支撑"),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v("测试系统"),
              ]),
              _vm._v("\n          "),
              _c("span", {
                staticStyle: { "font-size": "16px", color: "#999" },
              }),
              _vm._v(" "),
              _c(
                "el-carousel",
                {
                  staticStyle: {
                    width: "500px",
                    "margin-top": "5px",
                    "margin-left": "100px",
                  },
                  attrs: {
                    "indicator-position": "none",
                    loop: "true",
                    height: "50px",
                    arrow: "never",
                  },
                },
                _vm._l(100, function (item) {
                  return _c("el-carousel-item", { key: item }, [
                    _c("div", { staticClass: "attions" }, [
                      _vm._v("请注意！ 这是测试环境！ 请操作前确认环境！"),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _c(
          "el-dropdown",
          { staticStyle: { cursor: "pointer" }, attrs: { trigger: "click" } },
          [
            _c(
              "div",
              { staticClass: "el-dropdown-link", attrs: { id: "right-bar" } },
              [
                _c("img", {
                  attrs: { src: require("../../img/avater.png"), alt: "" },
                }),
                _vm._v("\n        " + _vm._s(_vm.name) + "\n        "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                _vm._v(" "),
                _c("el-divider", { attrs: { direction: "vertical" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/profile/index" } },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "a", icon: "el-icon-user" } },
                      [_vm._v("\n            个人中心\n          ")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  { attrs: { to: "/profile/download" } },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "b", icon: "el-icon-download" } },
                      [_vm._v("\n            下载中心\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "margin-right": "40px",
              cursor: "pointer",
              color: "#606266",
              "font-size": "14px",
            },
          },
          [
            _c("i", {
              staticClass: "el-icon-switch-button",
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: { "margin-left": "5px" },
                on: {
                  click: function ($event) {
                    return _vm.logout()
                  },
                },
              },
              [_vm._v("退出登录")]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }