"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20241104163900/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SysGroupPage = SysGroupPage;
exports.addGroupLeadPerformance = addGroupLeadPerformance;
exports.addGroupMemberPerformance = addGroupMemberPerformance;
exports.areaManager_analysisList = areaManager_analysisList;
exports.areaManager_analysisListExport = areaManager_analysisListExport;
exports.areaManager_analysisSubtotal = areaManager_analysisSubtotal;
exports.areaManager_list = areaManager_list;
exports.areaManager_listExport = areaManager_listExport;
exports.areaManager_updateManager = areaManager_updateManager;
exports.auditInfo = auditInfo;
exports.auditOcr = auditOcr;
exports.auditWholesale = auditWholesale;
exports.bandWeixinCode = bandWeixinCode;
exports.clearReceiptAddress = clearReceiptAddress;
exports.createGroup = createGroup;
exports.districts = districts;
exports.exportExtendDetail = exportExtendDetail;
exports.exportGroupCommissionDetail = exportGroupCommissionDetail;
exports.exportGroupLeadPerformance = exportGroupLeadPerformance;
exports.exportMaintainSalesStatistics = exportMaintainSalesStatistics;
exports.exportManagerDetail = exportManagerDetail;
exports.extendDetailPage = extendDetailPage;
exports.fastapply = fastapply;
exports.fastocr = fastocr;
exports.fastreg = fastreg;
exports.getGroupLeadPerformance = getGroupLeadPerformance;
exports.getGroupMemberPerformance = getGroupMemberPerformance;
exports.getGroupMemberPerformanceRuleByGroupId = getGroupMemberPerformanceRuleByGroupId;
exports.getGroupPerformancePage = getGroupPerformancePage;
exports.getGroupPerformanceRuleByGroup = getGroupPerformanceRuleByGroup;
exports.getInfo = getInfo;
exports.getMenu = getMenu;
exports.getSysGroupDetailByGroupId = getSysGroupDetailByGroupId;
exports.getSysGroupDictionary = getSysGroupDictionary;
exports.groupCommissionDetailPage = groupCommissionDetailPage;
exports.groupDictionary = groupDictionary;
exports.groupUserDictionary = groupUserDictionary;
exports.login = login;
exports.logout = logout;
exports.maintainSalesStatistics = maintainSalesStatistics;
exports.mallUserDetail = mallUserDetail;
exports.managerDetailPage = managerDetailPage;
exports.modifyMobile = modifyMobile;
exports.outSign_detail = outSign_detail;
exports.outSign_list = outSign_list;
exports.outSign_listExport = outSign_listExport;
exports.personOcr = personOcr;
exports.phoneCheck = phoneCheck;
exports.provinceList = provinceList;
exports.purchaseStaffBind_add_update = purchaseStaffBind_add_update;
exports.purchaseStaffBind_bindDetail = purchaseStaffBind_bindDetail;
exports.purchaseStaffBind_getStaffName = purchaseStaffBind_getStaffName;
exports.purchaseStaffBind_list = purchaseStaffBind_list;
exports.purchaseStaffBind_remove = purchaseStaffBind_remove;
exports.purchaseSupplierScope_addBrand = purchaseSupplierScope_addBrand;
exports.purchaseSupplierScope_brandList = purchaseSupplierScope_brandList;
exports.purchaseSupplierScope_brandSearch = purchaseSupplierScope_brandSearch;
exports.purchaseSupplierScope_categoryChildrenList = purchaseSupplierScope_categoryChildrenList;
exports.purchaseSupplierScope_categoryList = purchaseSupplierScope_categoryList;
exports.purchaseSupplierScope_deleteBrand = purchaseSupplierScope_deleteBrand;
exports.purchaseSupplierScope_updateCategory = purchaseSupplierScope_updateCategory;
exports.qrcode = qrcode;
exports.quickRegister = quickRegister;
exports.removeGroup = removeGroup;
exports.searchForAdd = searchForAdd;
exports.signGet = signGet;
exports.signSet = signSet;
exports.supplierByPhone = supplierByPhone;
exports.supplier_add = supplier_add;
exports.supplier_list = supplier_list;
exports.supplier_update = supplier_update;
exports.toUserList = toUserList;
exports.updateGroup = updateGroup;
exports.updateGroupMemberPerformanceRule = updateGroupMemberPerformanceRule;
exports.updateGroupPerformanceRule = updateGroupPerformanceRule;
exports.userAudit = userAudit;
exports.userDetail = userDetail;
exports.userECharts = userECharts;
exports.userEChartsScale = userEChartsScale;
exports.userIndustry = userIndustry;
exports.userLoginECharts = userLoginECharts;
exports.userManagerDetail = userManagerDetail;
exports.userManagerList = userManagerList;
exports.userOrderECharts = userOrderECharts;
exports.userPassword = userPassword;
exports.userUpdate = userUpdate;
exports.wholemanagerList = wholemanagerList;
var _request = _interopRequireDefault(require("@/utils/request"));
var _bossRequest = _interopRequireDefault(require("@/utils/bossRequest"));
var _wholesaleRequest = _interopRequireDefault(require("@/utils/wholesaleRequest"));
function login(data) {
  return (0, _bossRequest.default)({
    url: 'sysLogin/login',
    method: 'post',
    data: data
  });
}
function getInfo() {
  return (0, _bossRequest.default)({
    url: 'sys/temp/bossLoginRoles',
    method: 'POST'
  });
}
function getMenu() {
  return (0, _bossRequest.default)({
    url: 'sysMenu/nav',
    method: 'get'
  });
}
function logout() {
  return (0, _bossRequest.default)({
    url: 'sysLogin/logout',
    method: 'post'
  });
}
function userDetail() {
  return (0, _bossRequest.default)({
    url: 'sysUser/info',
    method: 'get'
  });
}

// 用户x修改密码
function userPassword(data) {
  return (0, _bossRequest.default)({
    url: 'sysUser/password',
    method: 'POST',
    data: data
  });
}

// 用户身份
function userIndustry() {
  return (0, _bossRequest.default)({
    url: 'sysCode/industry',
    method: 'get'
  });
}

// 用户数据图表
function userECharts(data) {
  return (0, _bossRequest.default)({
    url: 'user/userECharts',
    method: 'post',
    data: data
  });
}

// 用户数据统计
function userEChartsScale(data) {
  return (0, _bossRequest.default)({
    url: 'user/userEChartsScale',
    method: 'post',
    data: data
  });
}

// 用户登录日志统计
function userLoginECharts(data) {
  return (0, _bossRequest.default)({
    url: 'user/loginlog/eCharts',
    method: 'post',
    data: data
  });
}
// 用户登录日志统计
function userOrderECharts(data) {
  return (0, _bossRequest.default)({
    url: 'user/orderlog/userOrderEChartsDays',
    method: 'post',
    data: data
  });
}

// 用户OCR企业认证
function fastocr(data) {
  return (0, _request.default)({
    url: 'hx.baoxian/boss/login/companyOCR',
    method: 'post',
    data: data
  });
}

// 导出用户列表
function toUserList(data) {
  return (0, _bossRequest.default)({
    url: 'user/exportUserInfo?startDate=' + data.startDate + '&endDate=' + data.endDate,
    method: 'get'
  });
}

// 省市区总表
function districts() {
  return (0, _request.default)({
    url: 'hx.baoxian/common/district',
    method: 'get'
  });
}

// 省 列表
function provinceList() {
  return (0, _request.default)({
    url: 'hx.baoxian/common/district/province',
    method: 'get'
  });
}

// 企业微信绑定
function bandWeixinCode(data) {
  return (0, _bossRequest.default)({
    url: 'weixin/api/getuserinfo?state=' + data.state + '&code=' + data.code,
    method: 'get'
  });
}

// ==================OMS2.0======

/** *
 * @询价模块_供应商标签 -- 王涛
 * @员工管理
 * 2024-09-14
*/
// 员工列表
function purchaseStaffBind_list(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseStaffBind/list',
    method: 'post',
    data: data
  });
}
// 员工详情
function purchaseStaffBind_bindDetail(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseStaffBind/bindDetail',
    method: 'post',
    data: data
  });
}
// 1添加员工 2修改员工
function purchaseStaffBind_add_update(data, type) {
  return (0, _bossRequest.default)({
    url: type === 1 ? 'purchaseStaffBind/add' : 'purchaseStaffBind/update',
    method: 'post',
    data: data
  });
}
// 员工离职解绑
function purchaseStaffBind_remove(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseStaffBind/remove',
    method: 'post',
    data: data
  });
}
// 查询 员工姓名
function purchaseStaffBind_getStaffName(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseStaffBind/getStaffName',
    method: 'post',
    data: data
  });
}
/** *
 * @询价模块_供应商标签 -- 李杰
 * @代理品牌_优势品牌_业务门类
 * 2024-09-14
*/
// 代理品牌/优选品牌 获取
function purchaseSupplierScope_brandSearch(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseSupplierScope/brandSearch',
    method: 'post',
    data: data
  });
}
// 代理品牌/优选品牌  列表
function purchaseSupplierScope_brandList(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseSupplierScope/brandList',
    method: 'post',
    data: data
  });
}
// 添加 代理品牌/优选品牌
function purchaseSupplierScope_addBrand(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseSupplierScope/addBrand',
    method: 'post',
    data: data
  });
}
// 删除 代理品牌/优选品牌
function purchaseSupplierScope_deleteBrand(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseSupplierScope/deleteBrand',
    method: 'post',
    data: data
  });
}
// 业务门类  列表 一级
function purchaseSupplierScope_categoryList(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseSupplierScope/categoryList',
    method: 'post',
    data: data
  });
}
// 业务门类  列表 二级
function purchaseSupplierScope_categoryChildrenList(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseSupplierScope/categoryChildrenList',
    method: 'post',
    data: data
  });
}
// 更新业务门店
function purchaseSupplierScope_updateCategory(data) {
  return (0, _bossRequest.default)({
    url: 'purchaseSupplierScope/updateCategory',
    method: 'post',
    data: data
  });
}

// =======================================
// 用户详情
function userManagerDetail(data) {
  return (0, _bossRequest.default)({
    url: '/user/detail?supplierId=' + data,
    method: 'GET'
  });
}

// 商品概况手机-供应商查询
function supplierByPhone(data) {
  return (0, _bossRequest.default)({
    url: 'user/supplierByPhone?phone=' + data,
    method: 'GET'
  });
}

// 供应商列表(通过公司名称查询)
function searchForAdd(data) {
  return (0, _bossRequest.default)({
    url: 'user/searchForAdd?searchText=' + data,
    method: 'GET'
  });
}

// 更新供应商修改
function supplier_update(data) {
  return (0, _bossRequest.default)({
    url: 'user/update',
    method: 'post',
    data: data
  });
}

// 供应商列表
function supplier_list(data) {
  return (0, _bossRequest.default)({
    url: 'user/search',
    method: 'post',
    data: data
  });
}

// 供应商新增
function supplier_add(data) {
  return (0, _bossRequest.default)({
    url: 'user/add',
    method: 'post',
    data: data
  });
}

// 用户列表
function userManagerList(data) {
  return (0, _bossRequest.default)({
    url: 'user/managerList',
    method: 'post',
    data: data
  });
}
// 用户列表 分销
function wholemanagerList(data) {
  return (0, _bossRequest.default)({
    url: 'user/wholemanagerList',
    method: 'post',
    data: data
  });
}

// 用户上传审核信息
function auditInfo(data) {
  return (0, _bossRequest.default)({
    url: 'user/updateGongshangInfo',
    method: 'post',
    data: data
  });
}

// 商城用户详情(supplierId替代userId)
function mallUserDetail(data) {
  return (0, _bossRequest.default)({
    url: '/user/detail?supplierId=' + data,
    method: 'GET'
  });
}
// 更新用户信息
function userUpdate(data) {
  return (0, _bossRequest.default)({
    url: '/user/update',
    method: 'post',
    data: data
  });
}
// 审核用户信息  宠医申请 经销商申请
function userAudit(data) {
  return (0, _bossRequest.default)({
    url: '/user/audit',
    method: 'post',
    data: data
  });
}
// 审核用户信息 分销用户申请
function auditWholesale(data) {
  return (0, _bossRequest.default)({
    url: '/user/auditWholesale',
    method: 'post',
    data: data
  });
}

// 用户快捷注册2
function quickRegister(data) {
  return (0, _bossRequest.default)({
    url: '/user/quickRegister',
    method: 'post',
    data: data
  });
}
// 用户快捷注册
function fastreg(data) {
  return (0, _bossRequest.default)({
    url: '/user/reg',
    method: 'post',
    data: data
  });
}

// 用户企业商城入驻申请
function fastapply(data) {
  return (0, _bossRequest.default)({
    url: '/user/shopApply',
    method: 'post',
    data: data
  });
}

// 用户审核ocr
function auditOcr(data) {
  return (0, _request.default)({
    url: 'hx.baoxian/common/ocrOms?orcUrl=' + data,
    method: 'get'
  });
}
// 识别身份证获取身份信息
function personOcr(data) {
  return (0, _wholesaleRequest.default)({
    url: 'admin/ocr/idCard?fileUrl=' + data,
    method: 'get'
  });
}
// 清空地址
function clearReceiptAddress(data) {
  return (0, _wholesaleRequest.default)({
    url: 'boss/user/clearReceiptAddress',
    method: 'post',
    data: data
  });
}

// 分页查询商铺列表
function phoneCheck(data) {
  return (0, _bossRequest.default)({
    url: 'user/applySuccessShops',
    method: 'post',
    data: data
  });
}

// 修改用户手机号
function modifyMobile(data) {
  return (0, _bossRequest.default)({
    url: 'user/modifyMobile',
    method: 'post',
    data: data
  });
}

/**
 * @位置签到
 * kadiam 2023-1-5
*/
// 获取用户签到位置
function signGet(data) {
  return (0, _bossRequest.default)({
    url: 'user/signGet',
    method: 'post',
    data: data
  });
}
// 用户签到设置
function signSet(data) {
  return (0, _bossRequest.default)({
    url: 'user/signSet',
    method: 'post',
    data: data
  });
}

/**
 * @位置签到外勤
 * kadiam 2023-1-6
*/
// 外勤签到列表
function outSign_list(data) {
  return (0, _bossRequest.default)({
    url: 'outSign/list',
    method: 'post',
    data: data
  });
}
// 外勤签到列表 导出
function outSign_listExport(data) {
  return (0, _bossRequest.default)({
    url: 'outSign/listExport',
    method: 'post',
    data: data
  });
}
// 外勤签到详情
function outSign_detail(data) {
  return (0, _bossRequest.default)({
    url: 'outSign/detail/' + data,
    method: 'get'
  });
}
/**
 * @OA团队管理
 * kadiam 2023-1-10
*/
// 团队管理分页列表
function SysGroupPage(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/SysGroupPage',
    method: 'post',
    data: data
  });
}
// 查询团队字典表
function getSysGroupDictionary(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/getSysGroupDictionary',
    method: 'get',
    data: data
  });
}
// 团队用户字典表（已经排除了已有团队的成员）
function groupUserDictionary(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/groupUserDictionary',
    method: 'get',
    data: data
  });
}
// 创建团队
function createGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/createGroup',
    method: 'post',
    data: data
  });
}
// 根据团队id查询团队详情
function getSysGroupDetailByGroupId(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/getSysGroupDetailByGroupId?groupId=' + data,
    method: 'get'
  });
}
// 修改团队
function updateGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/updateGroup',
    method: 'post',
    data: data
  });
}
// 解散团队
function removeGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/removeGroup?groupId=' + data,
    method: 'get'
  });
}

/**
 * @OA绩效管理
 * kadiam 2023-1-10
*/
// 根据groupId查询团队负责人绩效规则
function getGroupPerformanceRuleByGroup(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/getGroupPerformanceRuleByGroup?groupId=' + data,
    method: 'get'
  });
}
// 更新团队负责人的绩效规则
function updateGroupPerformanceRule(data) {
  return (0, _bossRequest.default)({
    url: 'sys/group/updateGroupPerformanceRule',
    method: 'post',
    data: data
  });
}
// 根据groupId查询团队成员绩效规则
function getGroupMemberPerformanceRuleByGroupId(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupMember/getGroupMemberPerformanceRuleByGroupId?groupId=' + data,
    method: 'get'
  });
}
// 更新团队成员绩效规则
function updateGroupMemberPerformanceRule(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupMember/updateGroupMemberPerformanceRule',
    method: 'post',
    data: data
  });
}
// 根据月份查询团队经理绩效
function getGroupLeadPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/getGroupLeadPerformance',
    method: 'post',
    data: data
  });
}
// 结算团队经理本月绩效
function addGroupLeadPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/addGroupLeadPerformance',
    method: 'post',
    data: data
  });
}
// 查询团队内部成员
function groupDictionary(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/groupDictionary?groupId=' + data,
    method: 'get'
  });
}
// 根据月份查询团队成员绩效
function getGroupMemberPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/getGroupMemberPerformance',
    method: 'post',
    data: data
  });
}
// 结算团队成员本月绩效
function addGroupMemberPerformance(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupPerformance/addGroupMemberPerformance',
    method: 'post',
    data: data
  });
}

// 根据月份查询团队经理绩效分页列表 1
// 根据月份查询团队成员绩效分页列表 2
function getGroupPerformancePage(data, type) {
  return (0, _bossRequest.default)({
    url: type === '1' ? 'sys/groupPerformance/getGroupLeadPerformancePage' : 'sys/groupPerformance/getGroupMemberPerformancePage',
    method: 'post',
    data: data
  });
}
// 根据月份查询团队经理绩效分页列表导出 1
// 根据月份查询团队成员绩效分页列表导出 2
function exportGroupLeadPerformance(data, type) {
  return (0, _bossRequest.default)({
    url: type === '1' ? 'sys/groupPerformance/exportGroupLeadPerformance' : 'sys/groupPerformance/exportGroupMemberPerformance',
    method: 'post',
    data: data
  });
}

// 结算团队拓展
function extendDetailPage(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/extendDetailPage' : 'sys/groupDetailPerformance/memberExtendDetailPage',
    method: 'post',
    data: data
  });
}
// 结算团队维护
function managerDetailPage(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/managerDetailPage' : 'sys/groupDetailPerformance/memberManagerDetailPage',
    method: 'post',
    data: data
  });
}
// 结算团队拓展 导出
function exportExtendDetail(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/exportExtendDetail' : 'sys/groupDetailPerformance/exportMemberExtendDetail',
    method: 'post',
    data: data
  });
}
// 结算团队维护 导出
function exportManagerDetail(data, tab) {
  return (0, _bossRequest.default)({
    url: tab === 1 ? 'sys/groupDetailPerformance/exportManagerDetail' : 'sys/groupDetailPerformance/exportMemberManagerDetail',
    method: 'post',
    data: data
  });
}
// 业绩提成订单详情
function groupCommissionDetailPage(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupDetailPerformance/groupCommissionDetailPage',
    method: 'post',
    data: data
  });
}
// 业绩提成订单详情 导出
function exportGroupCommissionDetail(data) {
  return (0, _bossRequest.default)({
    url: 'sys/groupDetailPerformance/exportGroupCommissionDetail',
    method: 'post',
    data: data
  });
}

// 维护销售统计表
function maintainSalesStatistics(data) {
  return (0, _bossRequest.default)({
    url: 'receivable/maintainSalesStatistics',
    method: 'post',
    data: data
  });
}

// 维护销售统计表 导出
function exportMaintainSalesStatistics(data) {
  return (0, _bossRequest.default)({
    url: 'receivable/exportMaintainSalesStatistics',
    method: 'post',
    data: data
  });
}

// 区域管理
// 区域业务经理列表
function areaManager_list(data) {
  return (0, _bossRequest.default)({
    url: 'areaManager/list',
    method: 'post',
    data: data
  });
}
// 区域业务经理列表导出
function areaManager_listExport(data) {
  return (0, _bossRequest.default)({
    url: 'areaManager/listExport',
    method: 'post',
    data: data
  });
}
// 更新业务经理
function areaManager_updateManager(data) {
  return (0, _bossRequest.default)({
    url: 'areaManager/updateManager',
    method: 'post',
    data: data
  });
}
// 业务经理绩效列表
function areaManager_analysisList(data) {
  return (0, _bossRequest.default)({
    url: 'areaManager/analysisList',
    method: 'post',
    data: data
  });
}
// 业务经理绩效导出
function areaManager_analysisListExport(data) {
  return (0, _bossRequest.default)({
    url: 'areaManager/analysisListExport',
    method: 'post',
    data: data
  });
}
// 业务经理绩效小计
function areaManager_analysisSubtotal(data) {
  return (0, _bossRequest.default)({
    url: 'areaManager/analysisSubtotal',
    method: 'post',
    data: data
  });
}

// 获取带参数二维码
function qrcode(data) {
  return (0, _wholesaleRequest.default)({
    url: 'purchase/wxmp/qrcode/wx10610f05204e2dd1/' + data,
    method: 'GET'
  });
}