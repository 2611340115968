"use strict";

var _interopRequireWildcard = require("/home/devops/repos/1_20250402141554/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/home/devops/repos/1_20250402141554/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/home/devops/repos/1_20250402141554/node_modules/core-js/modules/es6.array.iterator.js");
require("/home/devops/repos/1_20250402141554/node_modules/core-js/modules/es6.promise.js");
require("/home/devops/repos/1_20250402141554/node_modules/core-js/modules/es6.object.assign.js");
require("/home/devops/repos/1_20250402141554/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _elTableInfiniteScroll = _interopRequireDefault(require("el-table-infinite-scroll"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _utils = require("@/utils");
var _moment = _interopRequireDefault(require("moment"));
var _vueClipboard = _interopRequireDefault(require("./../node_modules/vue-clipboard2"));
var _krryTransfer = _interopRequireDefault(require("krry-transfer"));
// a modern alternative to CSS resets

// global css

// internationalization
// icon
// permission control
// error log
// import vConsole from './assets/js/vconsole'
// global filters

// import { formatDate } from '@/utils'

_vue.default.filter('dateFormat', function (daraStr) {
  var pattern = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD HH:mm:ss';
  return (0, _moment.default)(daraStr).format(pattern);
});
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.isAuth = _utils.isAuth; // 权限方法
_vue.default.prototype.debounce = _utils.debounce; // 防抖
_vue.default.prototype.throttle = _utils.throttle; // 节流
_vue.default.prototype.validateForms = _utils.validateForms; // 多个表单同时验证
_vue.default.prototype.price_two = _utils.price_two; // 限制输入小数点后两位
_vue.default.prototype.formatterCellval = _utils.formatterCellval; // 表格空数据用 ‘ / ‘占位符占位
_vue.default.prototype.numCellval = _utils.numCellval; // 表格空数据用 ‘0 ‘占位符占位
_vue.default.prototype.moneyCellval = _utils.moneyCellval; // 表格空数据用 ‘ 0.00 ‘占位符占位
_vue.default.prototype.treeDataTranslate = _utils.treeDataTranslate; // 部门管理的树形结构
// Vue.prototype.transOrg = transOrg // 机构翻译
_vue.default.prototype.transUser = _utils.transUser; // 用户翻译
// Vue.prototype.transDict = transDict // 数据字典翻译
_vue.default.prototype.transMenuName = _utils.transMenuName; // 树形菜单名称转换
_vue.default.prototype.loadingSub = _utils.loadingSub; // 提交loading
_vue.default.prototype.defaultTimeRang = _utils.defaultTimeRang; // 默认日期区间获取
_vue.default.prototype.defaultTimeLimit = _utils.defaultTimeLimit; // 默认日期区间限制
_vue.default.prototype.toadyAddDay = _utils.toadyAddDay; // 计算当天之后的日期
_vue.default.prototype.getDifferTime = _utils.getDifferTime; // 计算两个日期相差的天数
_vue.default.use(_vueClipboard.default);
_vue.default.use(_krryTransfer.default);
_vue.default.use(_elTableInfiniteScroll.default);
_vue.default.config.productionTip = false;

// 用户缓存清理
var VUE_APP_VERSION = require("../package.json").version;
var vers = window.localStorage.getItem('appVersion');
if (VUE_APP_VERSION !== vers) {
  localStorage.clear();
  sessionStorage.clear();
  window.localStorage.setItem('appVersion', VUE_APP_VERSION);
  location.reload();
}
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});