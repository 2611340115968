"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250213160419/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TMaps = TMaps;
exports.addClass = addClass;
exports.byteLength = byteLength;
exports.cleanArray = cleanArray;
exports.createUniqueString = createUniqueString;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.defaultTimeLimit = defaultTimeLimit;
exports.defaultTimeRang = defaultTimeRang;
exports.formatDate = formatDate;
exports.formatTime = formatTime;
exports.formatterCellval = void 0;
exports.getDifferTime = getDifferTime;
exports.getQueryObject = getQueryObject;
exports.getTime = getTime;
exports.hasClass = hasClass;
exports.html2Text = html2Text;
exports.isAuth = isAuth;
exports.loadingSub = loadingSub;
exports.numCellval = exports.moneyCellval = void 0;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.price_two = price_two;
exports.removeClass = removeClass;
exports.throttle = throttle;
exports.toadyAddDay = toadyAddDay;
exports.toggleClass = toggleClass;
exports.transMenuName = transMenuName;
exports.transUser = transUser;
exports.treeDataTranslate = treeDataTranslate;
exports.uniqueArr = uniqueArr;
exports.validateForms = validateForms;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/home/devops/repos/1_20250213160419/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _moment = _interopRequireDefault(require("moment"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

// /**
//  * 防抖 节流函数
// */
var canRun = true;
//! !!!这里一定要把变量设在外面，不然会失效！我搞了好久才搞明白！
function debounce(fn, delay) {
  var context = this; // this指向发生变化，需要提出来
  var args = arguments;
  return function () {
    if (context.timeout) {
      clearTimeout(context.timeout);
    }
    context.timeout = setTimeout(function () {
      // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
      fn.apply(context, args);
    }, delay);
  };
}
function throttle(fn) {
  return function () {
    if (!canRun) return;
    canRun = false;
    setTimeout(function () {
      fn.apply(this, arguments);
      canRun = true;
    }, 3000);
  };
}
// /**
//  * @param {Function} func
//  * @param {number} wait
//  * @param {boolean} immediate
//  * @return {*}
//  */
// export function debounce(func, wait, immediate) {
//   let timeout, args, context, timestamp, result
//   console.log('-------immediate', immediate)
//   const later = function () {
//     // 据上一次触发时间间隔
//     const last = +new Date() - timestamp
//     console.log('-------last', last)

//     // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
//     if (last < wait && last > 0) {
//       timeout = setTimeout(later, wait - last)
//       console.log('-------timeout', timeout)
//     } else {
//       timeout = null
//       // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
//       if (!immediate) {
//         result = func.apply(context, args)
//         console.log('-------result', result)
//         if (!timeout) context = args = null
//       }
//     }
//   }
//   return function (...args) {
//     console.log('-------this', this)
//     context = this
//     timestamp = +new Date()
//     const callNow = immediate && !timeout
//     console.log('-------callNow', callNow)
//     // 如果延时不存在，重新设定延时
//     if (!timeout) timeout = setTimeout(later, wait)
//     if (callNow) {
//       result = func.apply(context, args)
//       context = args = null
//     }

//     return result
//   }
// }

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

/**
 * 树形数据转换
 * @param {*} data list数据
 * @param {*} id 主键ID
 * @param {*} pid 上级ID
 * @param childrenKey 子list数据的key
 */
function treeDataTranslate(data) {
  var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var pid = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'parentId';
  var childrenKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]][childrenKey]) {
        temp[data[k][pid]][childrenKey] = [];
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1;
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]][childrenKey].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

/**
 * 是否有权限
 * @param {*} key
 */
function isAuth(key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false;
}

// 时间戳转换
function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

// 多个表单同时验证
function validateForms(formRefs) {
  var objectList = [];
  var results = formRefs.map(function (formRef) {
    return new Promise(function (resolve, reject) {
      formRef.validate(function (valid, object) {
        if (valid) {
          resolve();
        } else {
          objectList.push(object);
          reject();
        }
      });
    });
  });
  return Promise.all(results).catch(function () {
    return Promise.reject(objectList);
  });
}
// el-table 表格空数据用 ‘ / ‘占位符占位
var formatterCellval = exports.formatterCellval = function formatterCellval(row, column, cellValue, index) {
  if (cellValue === '' || cellValue === null) {
    return '-';
  }
  return cellValue;
};
// el-table 表格空数据用 ‘ 0 ‘占位符占位 数字类型
var numCellval = exports.numCellval = function numCellval(row, column, cellValue, index) {
  if (cellValue === '' || cellValue === null) {
    return '0';
  }
  return cellValue;
};
// el-table 表格空数据用 ‘ 0.00 ‘占位符占位 金额类型
var moneyCellval = exports.moneyCellval = function moneyCellval(row, column, cellValue, index) {
  if (cellValue === '' || cellValue === null) {
    return '0.00';
  }
  return cellValue;
};

/**
 * 根据userId翻译为realName
 * @param userId
 */
function transUser(userId) {
  var userList = JSON.parse(sessionStorage.getItem('userList') || '[]');
  if (userList.length > 0) {
    for (var i = 0; i < userList.length; i++) {
      if (userList[i].userId === userId) {
        return userList[i].realName;
      }
    }
  }
  return '--';
}

/**
 * 根据菜单名进行路由actived
 * @param menuName
 */
function transMenuName(menuName, type) {
  var menuItem = document.querySelectorAll('.el-menu-item>span');
  for (var i in menuItem) {
    if (menuItem[i].innerText === menuName) {
      menuItem[i].parentElement.className = type ? 'el-menu-item is-active' : 'el-menu-item';
      return;
    }
  }
}

/**
 * 日期计算 -- 计算当天之后的日期
 * @number dayNum
 */
function toadyAddDay(dayNum) {
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * dayNum;
  today.setTime(targetday_milliseconds); // 注意，这行是关键代码

  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + '-' + tMonth + '-' + tDate;
}
function doHandleMonth(month) {
  var m = month;
  if (month.toString().length === 1) {
    m = '0' + month;
  }
  return m;
}

/**
 * @计算两个日期相差的天数
*/
function getDifferTime(startDate, endDate) {
  var startTime = new Date(Date.parse(startDate.replace(/-/g, '/'))).getTime();
  var endTime = new Date(Date.parse(endDate.replace(/-/g, '/'))).getTime();
  var dates = Math.abs(startTime - endTime) / (1000 * 60 * 60 * 24);
  return dates;
}
// /**
//  * input 事件值判断
//  * @string inputValue 值
//  * @string type 类型(intVal：正整数，float2Val：小数，保存后两位，maxVal：有最大值限制，minVal：有最小值限制，lengthVal：长度限制)
// */
// export function input_type_check(inputValue, type) {
//   return 1
// }

/**
 * @腾讯地图插件加载
*/
function TMaps(key) {
  return new Promise(function (resolve, reject) {
    window.init = function () {
      resolve(); // 注意这里
    };
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'http://map.qq.com/api/js?v=2.exp&callback=init&key=' + key;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

/**
 * @提交延时loading
*/
function loadingSub(msg, status) {
  var loading = this.$loading({
    lock: true,
    text: msg,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  if (status) {
    loading.close();
  }
}
function price_two(value) {
  value = value.replace(/[^\d.]/g, ''); // 清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ''); // 验证第一个字符是数字而不是字符
  value = value.replace(/\.{2,}/g, '.'); // 只保留第一个.清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  return value;
}
/**
 * @默认日期区间获取
*/

function defaultTimeRang(startDays, endDays, type) {
  //  type YYYY-MM-DD HH:mm:ss  YYYY-MM-DD
  var endTime = (0, _moment.default)((0, _moment.default)().day((0, _moment.default)().day() - endDays).endOf('day').valueOf()).format(type);
  var startTime = (0, _moment.default)((0, _moment.default)().day((0, _moment.default)().day() - startDays).startOf('day').valueOf()).format(type);
  var times = {
    startTime: startTime,
    endTime: endTime
  };
  return times;
}
/**
 * @默认日期区间限制
*/

function defaultTimeLimit(limitDays, timeRange) {
  var data = {
    date: ''
  };
  data.date = timeRange;
  console.log('defaultTimeLimit', data.date[0], data.date[1]);
  var days = Math.abs(new Date(data.date[1]) - new Date(data.date[0])) / (1000 * 60 * 60 * 24);
  console.log('defaultTimeLimit days', days);
  if (parseInt(days) > limitDays) {
    this.$message({
      message: '统计时间区间不可大于' + limitDays + '天！',
      type: 'error',
      duration: 2000
    });
    return false;
  } else {
    return true;
  }
}