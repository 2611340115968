"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250402141554/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/home/devops/repos/1_20250402141554/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
var _user = require("@/api/user");
var _jurisdictionx = require("@/api/jurisdictionx");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Account from './components/Account'
// import commonElDialog from '@/components/CommonElDialog'
var _default = exports.default = {
  name: 'Profile',
  // components: { UserCard, Account },
  // components: { UserCard, commonElDialog },
  components: {
    UserCard: _UserCard.default
  },
  data: function data() {
    return {
      user: {},
      activeTab: 'password',
      dataForm: {
        'newPassword': '',
        'password': ''
      },
      dataRule: {
        newPassword: [{
          required: true,
          message: '新密码不能为空',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '旧密码能为空',
          trigger: 'blur'
        }]
      },
      userDetail: null,
      dialogVisible: false,
      groupListCheck: [],
      groupList: [],
      omsInfoList: [],
      index: -1,
      dialogFrom: {
        name: '',
        userList: []
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'avatar', 'roles'])),
  created: function created() {
    // this.groupListCheck = JSON.parse(localStorage.getItem('groupListCheck')) || []
    // this.groupList = JSON.parse(localStorage.getItem('groupList')) || []
    this.getUser();
    // this.getOms()
  },
  methods: {
    getUser: function getUser() {
      var userDetail = JSON.parse(sessionStorage.getItem('userDetail'));
      this.userDetail = userDetail;
      var avatars = '';
      if (userDetail.sex === 1) {
        avatars = 'https://oss.xiaoyi120.com/guanwang/images/boy.png';
      } else {
        avatars = 'https://oss.xiaoyi120.com/guanwang/images/girl.png';
      }
      this.user = {
        id: userDetail.userId,
        name: userDetail.realName,
        sex: userDetail.sex,
        userName: userDetail.userName,
        email: userDetail.email || '',
        mobile: userDetail.mobile || '',
        avatar: avatars
      };
    },
    // 获取OMS人员列表
    getOms: function getOms() {
      var _this = this;
      (0, _jurisdictionx.userList)({
        page: 1,
        limit: 1000,
        userName: ''
      }).then(function (res) {
        _this.omsInfoList = res.data.records;
      });
    },
    // 创建组
    creatGroup: function creatGroup() {
      // 存储为匹配数据
      this.groupListCheck.push(this.dialogFrom);
      localStorage.setItem('groupListCheck', JSON.stringify(this.groupListCheck));
      var info = {
        name: this.dialogFrom.name,
        userList: []
      };
      for (var i in this.dialogFrom.userList) {
        var parms = {
          id: this.dialogFrom.userList[i],
          name: ''
        };
        info.userList.push(parms);
      }
      if (this.index >= 0) {
        this.groupList[this.index] = info;
        this.index = -1;
      } else {
        this.groupList.push(info);
      }
      this.load(this.groupList);
      localStorage.setItem('groupList', JSON.stringify(this.groupList));
      this.dialogVisible = false;
    },
    // 复合组的信息
    load: function load(item) {
      for (var i in item) {
        for (var j in item[i].userList) {
          for (var k in this.omsInfoList) {
            if (item[i].userList[j].id === this.omsInfoList[k].userId) {
              item[i].userList[j].name = this.omsInfoList[k].realName;
            }
          }
        }
      }
    },
    // 修改分组
    changeGroup: function changeGroup(item, index) {
      this.index = index;
      var info = {
        name: item.name,
        userList: []
      };
      for (var i in item.userList) {
        info.userList.push(item.userList[i].id);
      }
      this.dialogFrom = info;
      this.dialogVisible = true;
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _user.userPassword)(_this2.dataForm).then(function (res) {
            if (res && res.code === 0) {
              _this2.visible = false;
              _this2.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500
              });
            }
          });
        }
      });
    }
  }
};