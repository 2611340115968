"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  district: function district(state) {
    return state.app.district;
  },
  category: function category(state) {
    return state.app.category;
  },
  userSex: function userSex(state) {
    return state.app.userSex;
  },
  salesUnit: function salesUnit(state) {
    return state.app.salesUnit;
  },
  productTag: function productTag(state) {
    return state.app.productTag;
  },
  orgTreeList: function orgTreeList(state) {
    return state.app.orgTreeList;
  },
  companyCategory: function companyCategory(state) {
    return state.app.companyCategory;
  },
  storeRecord: function storeRecord(state) {
    return state.app.storeRecord;
  },
  logistics: function logistics(state) {
    return state.app.logistics;
  },
  companyking: function companyking(state) {
    return state.app.companyking;
  },
  companyName: function companyName(state) {
    return state.app.companyName;
  },
  userInfoCertificationType: function userInfoCertificationType(state) {
    return state.app.userInfoCertificationType;
  },
  productMngSelfOperatedStatus: function productMngSelfOperatedStatus(state) {
    return state.app.productMngSelfOperatedStatus;
  },
  purchaseOrderSupplierPayment: function purchaseOrderSupplierPayment(state) {
    return state.app.purchaseOrderSupplierPayment;
  },
  purchaseOrderStatus: function purchaseOrderStatus(state) {
    return state.app.purchaseOrderStatus;
  },
  purchaseSource: function purchaseSource(state) {
    return state.app.purchaseSource;
  },
  purchaseLessStatus: function purchaseLessStatus(state) {
    return state.app.purchaseLessStatus;
  },
  purchasePlanGoodsStatus: function purchasePlanGoodsStatus(state) {
    return state.app.purchasePlanGoodsStatus;
  },
  storeType: function storeType(state) {
    return state.app.storeType;
  },
  storeRecordType: function storeRecordType(state) {
    return state.app.storeRecordType;
  },
  storeRecordTips: function storeRecordTips(state) {
    return state.app.storeRecordTips;
  },
  storeRecordClassify: function storeRecordClassify(state) {
    return state.app.storeRecordClassify;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  userinfo: function userinfo(state) {
    return state.user.userinfo;
  },
  sysOrgQueryAll: function sysOrgQueryAll(state) {
    return state.user.sysOrgQueryAll;
  },
  certificationFlag: function certificationFlag(state) {
    return state.user.certificationFlag;
  },
  csrf: function csrf(state) {
    return state.app.csrf;
  }
};
var _default = exports.default = getters;