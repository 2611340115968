"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250402141554/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueQr = _interopRequireDefault(require("vue-qr"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    vueQr: _vueQr.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: '',
          cpUserId: ''
        };
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      userInfos: {},
      imageUrl: require("../../../img/logosq.png"),
      codes: ''
    };
  },
  created: function created() {
    this.userInfos = this.$store.getters.userinfo;
    console.log('----------userInfo', this.userInfos);
  },
  methods: {
    openCode: function openCode() {
      this.codes = '';
      this.dialogVisible = true;
      this.codes = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + process.env.VUE_APP_BASE_appid + '&redirect_uri=' + process.env.VUE_APP_BASE_https + 'banweix?id=' + this.userInfos.userId + '&response_type=code&scope=snsapi_userinfo&state=#wechat_redirect';
    }
  }
};