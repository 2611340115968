"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Banweix',
  data: function data() {
    return {
      pathAddress: '',
      loading: false,
      form: {
        state: '',
        code: ''
      }
    };
  },
  created: function created() {
    this.pathAddress = window.location.href;
    this.addressCheck();
  },
  methods: {
    addressCheck: function addressCheck() {
      var urlcode = this.pathAddress.split('?')[1];
      urlcode = urlcode.replace('orderId=', '');
      var codex = urlcode.split('&')[0];
      this.form.code = codex.split('code=')[1]; // code
      console.log('--------------this.form.code', this.form.code);
      var urlstate = this.pathAddress.split('?')[2];
      this.form.state = urlstate.split('id=')[1]; // state
      console.log('--------------this.form.state', this.form.state);
    },
    getCode: function getCode() {
      var _this = this;
      (0, _user.bandWeixinCode)(this.form).then(function (res) {
        _this.loading = true;
      });
    }
  }
};