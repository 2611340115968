"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CustomElDrawer',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    wrapperClosable: {
      type: Boolean,
      default: false
    },
    appendtobody: {
      type: Boolean,
      default: false
    },
    destroy: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    // success/warning/info/error
    size: {
      type: String,
      default: '30%'
    }
  },
  data: function data() {
    return {
      alertInfo: {
        show: false,
        title: '提示',
        type: 'info'
      },
      alertLeft: ''
    };
  },
  computed: {},
  mounted: function mounted() {
    var alertLeft = document.querySelector('.el-drawer').style.width;
    this.alertLeft = alertLeft.slice(0, alertLeft.length - 2) / 4 + 'px';
  },
  methods: {
    close: function close() {
      this.$emit('update:visible', false);
      this.$emit('closed', 0);
    },
    error: function error(msg) {
      this.pop({
        show: true,
        title: String(msg),
        type: 'error'
      });
    },
    success: function success(msg) {
      this.pop({
        show: true,
        title: String(msg),
        type: 'success'
      });
    },
    info: function info(msg) {
      this.pop({
        show: true,
        title: String(msg),
        type: 'info'
      });
    },
    warning: function warning(msg) {
      this.pop({
        show: true,
        title: String(msg),
        type: 'warning'
      });
    },
    pop: function pop(data) {
      var _this = this;
      this.$message.closeAll();
      this.alertInfo = data;
      setTimeout(function () {
        _this.alertInfo.show = false;
      }, 3000);
    }
  }
};