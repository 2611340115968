"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20241104163900/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDiscountRefund = addDiscountRefund;
exports.addPresentProduct = addPresentProduct;
exports.dealDiscountRefund = dealDiscountRefund;
exports.expressList = expressList;
exports.expressPrice = expressPrice;
exports.mallOrderEvent_add = mallOrderEvent_add;
exports.mallOrderEvent_events = mallOrderEvent_events;
exports.mallOrderEvent_eventsV2 = mallOrderEvent_eventsV2;
exports.mallOrderEvent_log = mallOrderEvent_log;
exports.mallOrderEvent_productDetail = mallOrderEvent_productDetail;
exports.mallOrderEvent_statusTotal = mallOrderEvent_statusTotal;
exports.mallOrderEvent_update = mallOrderEvent_update;
exports.mallOrderRefund_add = mallOrderRefund_add;
exports.mallOrderRefund_reason = mallOrderRefund_reason;
exports.mallOrderRefund_refundList = mallOrderRefund_refundList;
exports.mallOrderRefund_sendZhRefund = mallOrderRefund_sendZhRefund;
exports.mallOrderRefund_update = mallOrderRefund_update;
exports.mallOrderRefund_updateRefund = mallOrderRefund_updateRefund;
exports.mallOrder_detail = mallOrder_detail;
exports.mallOrder_finishRefundList = mallOrder_finishRefundList;
exports.mallOrder_orderDetailPromotion = mallOrder_orderDetailPromotion;
exports.mallOrder_orderRemark = mallOrder_orderRemark;
exports.mallOrder_orders = mallOrder_orders;
exports.mallOrder_productList = mallOrder_productList;
exports.mallOrder_updateMallOrderPurchaseInfo = mallOrder_updateMallOrderPurchaseInfo;
exports.mallOrder_updateSellerInfo = mallOrder_updateSellerInfo;
exports.mallOrder_updatetypeno = mallOrder_updatetypeno;
exports.presentProductDictionary = presentProductDictionary;
exports.refundExpressPrice = refundExpressPrice;
exports.updateRefundByEvent = updateRefundByEvent;
exports.updateRefundByFlow = updateRefundByFlow;
var _bossRequest = _interopRequireDefault(require("@/utils/bossRequest"));
// ------------订单看板boss------------

// 任务列表（看板）
function mallOrderEvent_events(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/events',
    method: 'POST',
    data: data
  });
}
// 任务列表（看板）
function mallOrderEvent_eventsV2(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/eventsV2',
    method: 'POST',
    data: data
  });
}

// 任务列表（看板）快速处理
function mallOrderEvent_update(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/update',
    method: 'POST',
    data: data
  });
}

// 订单看板数据
function mallOrderEvent_statusTotal() {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/statusTotal',
    method: 'GET'
  });
}

// 订单事件操作日志
function mallOrderEvent_log(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/list?orderId=' + data,
    method: 'GET',
    data: data
  });
}
// ------------订单列表boss------------
// 订单列表
// export function mallOrder_orders(data) {
//   return serviceboss({ url: 'mallOrder/orders', method: 'post', data })
// }

// 订单列表 -- 订单信息
function mallOrder_orders(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/orderList',
    method: 'post',
    data: data
  });
}
// 订单列表 -- 商品信息
function mallOrder_productList(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/productList?orderId=' + data,
    method: 'GET'
  });
}

// 订单修改销售类型
function mallOrder_updatetypeno(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/updateTypeNo',
    method: 'POST',
    data: data
  });
}

// 订单促销结果
function mallOrder_orderDetailPromotion(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/orderDetailPromotion?orderId=' + data,
    method: 'get'
  });
}
// 订单详情
function mallOrder_detail(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/detail?orderId=' + data,
    method: 'get'
  });
}

// 订单修改销售类型
function mallOrder_orderRemark(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/orderRemark',
    method: 'POST',
    data: data
  });
}

// 订单完结退款列表
function mallOrder_finishRefundList(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/finishRefundList?orderId=' + data,
    method: 'get'
  });
}

// 操作订单商品列表
function mallOrderEvent_productDetail(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/productDetail?orderId=' + data,
    method: 'GET'
  });
  // return serviceboss({ url: 'mallOrderEvent/productDetail?orderId=' + data.orderId + '&refundType=' + data.refundType, method: 'GET' })
}

// 添加操作事件
function mallOrderEvent_add(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderEvent/add',
    method: 'POST',
    data: data
  });
}

// 修改订单配货信息
function mallOrder_updateMallOrderPurchaseInfo(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/updateMallOrderPurchaseInfo',
    method: 'POST',
    data: data
  });
}
// 卖家信息修改(商品信息修改)
function mallOrder_updateSellerInfo(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/updateSellerInfo',
    method: 'POST',
    data: data
  });
}
// 订单新增退款(废弃)
function mallOrderRefund_add(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/add',
    method: 'POST',
    data: data
  });
}
// 订单退款操作
function updateRefundByFlow(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/updateRefundByFlow',
    method: 'POST',
    data: data
  });
}

// 订单退款处理(废弃)
function mallOrderRefund_update(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/update',
    method: 'POST',
    data: data
  });
}
// 订单退款修改退款(废弃)
function mallOrderRefund_updateRefund(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/updateRefund',
    method: 'POST',
    data: data
  });
}
// 订单退款退款
function updateRefundByEvent(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/updateRefundByEvent',
    method: 'POST',
    data: data
  });
}

// 退款原因描述
function mallOrderRefund_reason(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/reason',
    method: 'GET'
  });
}
// 订单商品退款明细
function mallOrderRefund_refundList(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/refundList?orderId=' + data,
    method: 'GET'
  });
  // return serviceboss({ url: 'mallOrderRefund/refundList?orderId=' + data.orderId + '&refundType=' + data.refundType, method: 'GET' })
}
// 重新发起招行退款
function mallOrderRefund_sendZhRefund(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/sendZhRefund',
    method: 'POST',
    data: data
  });
}

// 物流包裹查看
function expressList(data) {
  return (0, _bossRequest.default)({
    url: 'mallorderexpress/expressList?orderId=' + data,
    method: 'GET'
  });
}
// 赠品商品字典
function presentProductDictionary(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/presentProductDictionary',
    method: 'POST',
    data: data
  });
}
// 添加零元商品
function addPresentProduct(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrder/addPresentProduct',
    method: 'POST',
    data: data
  });
}
// 新增优惠退款
function addDiscountRefund(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/addDiscountRefund',
    method: 'POST',
    data: data
  });
}
// 处理优惠退款
function dealDiscountRefund(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/dealDiscountRefund',
    method: 'POST',
    data: data
  });
}
// 运费明细
function expressPrice(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/expressPrice',
    method: 'POST',
    data: data
  });
}
// 运费退款操作
function refundExpressPrice(data) {
  return (0, _bossRequest.default)({
    url: 'mallOrderRefund/refundExpressPrice',
    method: 'POST',
    data: data
  });
}