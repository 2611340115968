"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { login } from '../../api/user'
var _default = exports.default = {
  inject: ['reload'],
  name: 'Login',
  data: function data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      versions: process.env.VUE_APP_VERSIONS
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.checkChrome();
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    // 判断是否为chrome
    checkChrome: function checkChrome() {
      var _this = this;
      var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
      console.log(userAgent.indexOf('Chrome'));
      if (userAgent.indexOf('Chrome') !== 81) {
        // 判断是否Chrome浏览器
        this.$notify({
          title: '系统提示！',
          dangerouslyUseHTMLString: true,
          message: '推荐使用Chrome浏览器进行登录！<H4>点击当前卡片可快速下载</H4>',
          type: 'info',
          duration: 0,
          position: 'top-right',
          onClick: function onClick() {
            window.open('https://oss.xiaoyi120.com/Boss/ChromeSetup.exe');
            _this.$notify.closeAll();
          }
        });
      }
    },
    relogin: function relogin() {
      this.reload();
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          var from = {
            username: _this3.loginForm.username,
            password: _this3.loginForm.password
          };
          _this3.$store.dispatch('user/login', from).then(function (userId) {
            _this3.$store.dispatch('user/userDetail');
            _this3.$router.push({
              path: '/',
              query: _this3.otherQuery
            });
            // 加载vuex
            _this3.$store.dispatch('app/setStoreRecord'); // 全部仓库列表
            _this3.$store.dispatch('app/typeList', 'sys_user_sex'); // 性别(0:男 1:女 2:未知)
            _this3.$store.dispatch('app/typeList', 'sys_sales_unit'); // 销售单位
            _this3.$store.dispatch('app/setProductTag'); // 商品标签
            _this3.$store.dispatch('app/typeList', 'company_category'); // 供应商类型(1:医院 2:人医 3:流通商-经销/代理 4:制造商 5:采购供应商)
            _this3.$store.dispatch('app/typeList', 'company_king'); // 供应商行业(1:人 2:牙 3:宠)
            _this3.$store.dispatch('app/typeList', 'user_info_certification_type'); // 认证状态（0:待认证 1:通过 2:失败 -1:资料不完整）
            _this3.$store.dispatch('app/typeList', 'product_mng_self_operated_status'); // 自营类型(1:非自营商品 2:自营后仓 3:前店)
            _this3.$store.dispatch('app/typeList', 'purchase_order_supplier_payment'); // 供应商付款类型(1:账期付款 2:预付货款 3:货到付款)
            _this3.$store.dispatch('app/typeList', 'purchase_order_status'); // 采购单类型(-1:已取消 0:待提交 1:待入库 2:部分入库 3:已入库)
            _this3.$store.dispatch('app/typeList', 'purchase_source'); // 渠道来源(1:网上商城 2:前店业务 3:后仓业务)
            _this3.$store.dispatch('app/typeList', 'purchase_less_status'); // 缺货状态(1:待查找 2:已替代 3:已新增 4:已放弃)
            _this3.$store.dispatch('app/typeList', 'purchase_plan_goods_status'); // 采购商品状态(1:待入库 2:部分入库 3:全部入库)
            _this3.$store.dispatch('app/typeList', 'store_type'); // 仓库类型(1:中心货架 2:前店(无忧货架) 3:后仓)
            _this3.$store.dispatch('app/typeList', 'store_record_type'); // 仓库记录出入库类型(1:入库 2:出库 3:调入 4:调出)
            _this3.$store.dispatch('app/typeList', 'store_record_tips'); // 仓库记录小提示(0:外部来源 1:采购订单 2:仓库助手)
            _this3.$store.dispatch('app/typeList', 'store_record_classify'); // 仓库记录操作类型(10:订单入库 11:退货入库 12:赔偿入库 13:盘存入库 14:其他入库 20:订单出库 21:破损出库 22:丢失出库 23:盘存出库 24:其他出库)
            _this3.$store.dispatch('app/setcategory'); // 商品分类
            _this3.$store.dispatch('app/setLogistics'); // 物流信息
            _this3.$store.dispatch('user/setSysorgqueryall'); // 全部组织机构
            _this3.loading = false;
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    } // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  }
};