var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: "11" } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              staticClass: "boss_dashboard",
              attrs: {
                src: "https://oss.xiaoyi120.com/Boss/index/boss_dashboard.png",
                mode: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              {
                staticClass: "dashboard-page",
                staticStyle: {
                  height: "146px",
                  overflow: "hidden",
                  background:
                    "linear-gradient(180deg, #d4eaf9 0%, #ecf6ff 29%, #ffffff 100%)",
                  "padding-left": "15px",
                },
              },
              [
                _c("div", { staticClass: "flex-r-sb-c" }, [
                  _c("div", { staticClass: "dashboard_process_title" }, [
                    _vm._v("产品功能更新"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "font-size": "14px",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          _vm.drawerVisible = true
                        },
                      },
                    },
                    [_vm._v("更多")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  _vm._l(_vm.tabList, function (item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        { ref: "tab", refInFor: true, attrs: { id: "tab" } },
                        _vm._l(
                          _vm.monthList,
                          function (item_process, index_process) {
                            return _c(
                              "div",
                              { key: index_process },
                              [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v(_vm._s(item_process.theme)),
                                ]),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item_process.content),
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-divider"),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "common-table-filter-box" },
              [
                _c(
                  "el-tabs",
                  [
                    _c("el-tab-pane", [
                      _c(
                        "div",
                        {
                          staticClass: "flex-r-c-c",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "font-size": "18px" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTabs(2)
                                },
                              },
                            },
                            [_vm._v("待处理")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "board_tips_num",
                              staticStyle: {
                                background: "rgba(255, 77, 79, 0.3)",
                                color: "#ff4d4f",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTabs(2)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.boardNum.waitPorcess)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-tab-pane", [
                      _c(
                        "div",
                        {
                          staticClass: "flex-r-c-c",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "font-size": "18px" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTabs(1)
                                },
                              },
                            },
                            [_vm._v("我发起的")]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-tab-pane", [
                      _c(
                        "div",
                        {
                          staticClass: "flex-r-c-c",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "font-size": "18px" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTabs(3)
                                },
                              },
                            },
                            [_vm._v("已完成")]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  { ref: "form", attrs: { "label-width": "90px" } },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 45 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "买家信息" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "mobile", $$v)
                                    },
                                    expression: "searchForm.mobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "订单编号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.orderNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "orderNo", $$v)
                                    },
                                    expression: "searchForm.orderNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "订单类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.searchForm.orderType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "orderType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.orderType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "商城订单", value: 1 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "分销订单", value: 2 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "任务类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.searchForm.eventType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "eventType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.eventType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "待补货", value: 2 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "退货", value: 4 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "退款", value: 5 },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "其他", value: 0 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "订单时间" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    editable: false,
                                    type: "datetimerange",
                                    align: "center",
                                    "unlink-panels": "",
                                    "range-separator": "至",
                                    "start-placeholder": "开始时间",
                                    "end-placeholder": "结束时间",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "default-time": ["00:00:00", "23:59:59"],
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "time", $$v)
                                    },
                                    expression: "searchForm.time",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-form-item",
                              { staticStyle: { float: "right" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "search-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetSearch()
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "search-btn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.searchForm.page = 1
                                        _vm.search()
                                      },
                                    },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "common-table-filter-box",
                staticStyle: { "margin-top": "10px" },
              },
              [
                _c("div", { staticClass: "border_list_title" }, [
                  _vm._v("工单信息"),
                ]),
                _vm._v(" "),
                _vm.boardInfo.length > 0
                  ? _vm._l(_vm.boardInfo, function (info, info_index) {
                      return _c(
                        "div",
                        {
                          key: info_index,
                          class:
                            _vm.choosed === info_index
                              ? "border_list border_list_actived flex-r-s-s"
                              : "border_list flex-r-s-s",
                          style: {
                            borderBottom:
                              info_index === _vm.boardInfo.length ? "none" : "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "96%" } },
                            [
                              _c(
                                "el-descriptions",
                                { attrs: { column: "4" } },
                                [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "订单编号" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            cursor: "pointer",
                                            color: "#0689e7",
                                            "text-decoration": "underline",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.toOrderPlay(info)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(info.orderNo)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "订单状态" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          [
                                            "待分拣",
                                            "处理中",
                                            "待出库",
                                            "已出库",
                                          ][info.outputStatus]
                                        ) + "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "订单类型" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          ["", "商城订单", "分销订单"][
                                            info.orderType
                                          ]
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "任务类型" } },
                                    [
                                      _c("div", { staticClass: "flex-r-s-s" }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              cursor: "pointer",
                                              color: "#0689e7",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  [
                                                    "其他",
                                                    "替换",
                                                    "补货",
                                                    "代发货",
                                                    "退货",
                                                    "退款",
                                                  ][info.eventType]
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        info.refundStatus === 0
                                          ? _c("span", [_vm._v("（拦截出库）")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        info.refundStatus === 1
                                          ? _c("span", [
                                              _vm._v("（退货入库-未填写快递）"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        info.refundStatus === 2
                                          ? _c("span", [
                                              _vm._v(
                                                "（退货入库-" +
                                                  _vm._s(info.expressName) +
                                                  "-" +
                                                  _vm._s(info.expressNo) +
                                                  "）"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "公司名称" } },
                                    [_vm._v(_vm._s(info.companyName))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "注册账号" } },
                                    [_vm._v(_vm._s(info.mobile))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "所在区域" } },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(info.receiptProvince) +
                                          "-" +
                                          _vm._s(info.receiptCity) +
                                          "-" +
                                          _vm._s(info.receiptArea) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "会员等级" } },
                                    [_vm._v(_vm._s(info.xxxxx))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "收货地址" } },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(info.receiptProvince) +
                                          _vm._s(info.receiptCity) +
                                          _vm._s(info.receiptArea) +
                                          _vm._s(info.receiptAddress) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "收货人" } },
                                    [_vm._v(_vm._s(info.receiptPersonName))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "联系方式" } },
                                    [_vm._v(_vm._s(info.receiptMobile))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "订单时间" } },
                                    [_vm._v(_vm._s(info.orderCreateTime))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.searchForm.eventOperate === 2
                            ? _c(
                                "div",
                                {
                                  staticClass: "left_btn flex-r-c-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeOrder(info, info_index)
                                    },
                                  },
                                },
                                [_vm._v("\n              操作")]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "left_btn2 flex-r-c-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeOrder(info, info_index)
                                    },
                                  },
                                },
                                [_vm._v("\n              查看")]
                              ),
                        ]
                      )
                    })
                  : _c("el-empty", { attrs: { description: "暂无工单" } }),
                _vm._v(" "),
                _c("br"),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: ".common-drawer-box" },
        [
          _c(
            "common-el-drawer",
            {
              attrs: { visible: _vm.drawerVisible, size: "900px" },
              on: {
                "update:visible": function ($event) {
                  _vm.drawerVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "common-detail-box" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("产品功能更新")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dashboard-page" }, [
                  _c("img", {
                    staticClass: "boss_pro",
                    attrs: {
                      src: "https://oss.xiaoyi120.com/Boss/index/boss_pro.png",
                      mode: "",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "40px",
                        "margin-bottom": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("产品历程")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "800px",
                        display: "flex",
                        "flex-direction": "row",
                        "align-items": "flex-start",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left_process" },
                        _vm._l(_vm.tabList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class:
                                _vm.leftChoosed === index
                                  ? "left_item left_item_actived"
                                  : "left_item",
                              on: {
                                click: function ($event) {
                                  return _vm.changeLeft(item, index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.yearMonthName) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "tab",
                          staticClass: "right_process",
                          attrs: { id: "tab" },
                        },
                        _vm._l(
                          _vm.monthList,
                          function (item_process, index_process) {
                            return _c(
                              "div",
                              { key: index_process },
                              [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v(_vm._s(item_process.theme)),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "releasetime" }, [
                                  _vm._v(
                                    _vm._s(
                                      item_process.createTime.split(" ")[0]
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item_process.content),
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-divider"),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: ".common-drawer-box" },
        [
          _c(
            "common-el-drawer",
            {
              attrs: { visible: _vm.fastVisible, size: "900px" },
              on: {
                "update:visible": function ($event) {
                  _vm.fastVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "common-detail-box" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("快速处理")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "fast_play" },
                  [
                    _c("div", { staticClass: "fast_top flex-r-sb-c" }, [
                      _c("div", { staticClass: "top_title" }, [
                        _vm._v("处理进度"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "top_history",
                          on: { click: _vm.openHistory },
                        },
                        [
                          _c("i", { staticClass: "el-icon-time" }),
                          _vm._v(" 历史工单"),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticClass: "common-el-table",
                        attrs: { data: _vm.historyTbaleList0, stripe: "" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "productMngCode",
                            label: "商品信息",
                            "show-overflow-tooltip": "",
                            width: "200px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.orderDetailEventRes,
                                  function (
                                    item_orderDetailEventRes,
                                    index_orderDetailEventRes
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index_orderDetailEventRes,
                                        staticClass: "mt-10",
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item_orderDetailEventRes.productName
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "f-14 f-grey" },
                                          [
                                            _vm._v(
                                              " 品牌：" +
                                                _vm._s(
                                                  item_orderDetailEventRes.productBrand
                                                ) +
                                                "\n                    型号：" +
                                                _vm._s(
                                                  item_orderDetailEventRes.productModel
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                )
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "eventTypeName",
                            label: "事件类型",
                            width: "120px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "备注", width: "300px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.detailLogs,
                                  function (item_detailLogs, index_detailLogs) {
                                    return _c(
                                      "div",
                                      { key: index_detailLogs },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "intro-text" },
                                          [
                                            _vm._v(
                                              "\n                    【" +
                                                _vm._s(
                                                  item_detailLogs.operator
                                                ) +
                                                "】：" +
                                                _vm._s(item_detailLogs.remark)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                )
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "eventStatusName",
                            label: "处理状态",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "organizer",
                            label: "提交人",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "processor",
                            label: "处理人",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "originalTime",
                            label: "提交时间",
                            width: "150px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "processTime",
                            label: "处理时间",
                            width: "150px",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-steps",
                      {
                        attrs: {
                          direction: "vertical",
                          active: 1,
                          space: "70px",
                        },
                      },
                      [
                        _vm.fastInfo.eventProfix === "待处理" ||
                        _vm.fastInfo.eventProfix === "我发起的"
                          ? _c("el-step", {
                              attrs: {
                                title:
                                  "【" +
                                  _vm.fastInfo.processorName +
                                  "】(待处理)",
                                icon: "el-icon-time",
                                description: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.fastInfo.detailLogs,
                          function (log_item, log_index) {
                            return _c("el-step", {
                              key: log_index,
                              attrs: {
                                title:
                                  "【" +
                                  log_item.operator +
                                  "】" +
                                  log_item.remark,
                                icon: "el-icon-finished",
                                description: log_item.createTime,
                              },
                            })
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.searchForm.eventOperate === 2
                      ? _c(
                          "div",
                          [
                            _vm.fastInfo.eventType === 0 ||
                            _vm.fastInfo.eventType === 2
                              ? _c(
                                  "el-form",
                                  { attrs: { "label-width": "80px" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "快速处理" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                autosize: {
                                                  minRows: 4,
                                                  maxRows: 8,
                                                },
                                                placeholder: "请输入备注...",
                                              },
                                              model: {
                                                value: _vm.fastInfo.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.fastInfo,
                                                    "remark",
                                                    $$v
                                                  )
                                                },
                                                expression: "fastInfo.remark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "下一步：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "40%" },
                                                model: {
                                                  value: _vm.fastInfo.opreate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fastInfo,
                                                      "opreate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fastInfo.opreate",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "已完成",
                                                    value: 1,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "递交处理",
                                                    value: 0,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.fastInfo.opreate === 0
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "处理人" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      "value-key": "userId",
                                                      filterable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fastInfo.employeeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.fastInfo,
                                                          "employeeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fastInfo.employeeId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.userselectList,
                                                    function (item_user) {
                                                      return _c("el-option", {
                                                        key: item_user.userId,
                                                        attrs: {
                                                          label:
                                                            item_user.realName,
                                                          value:
                                                            item_user.userId,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footer" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.fastVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _vm.searchForm.eventOperate === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.fastSub(
                                  _vm.item_board,
                                  _vm.index_board
                                )
                              },
                            },
                          },
                          [_vm._v("立即提交\n          ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: ".common-drawer-box" },
        [
          _c(
            "common-el-drawer",
            {
              attrs: { visible: _vm.historyVisible, size: "1416px" },
              on: {
                "update:visible": function ($event) {
                  _vm.historyVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "common-detail-box" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("历史工单")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "history_page" },
                  [
                    _c(
                      "el-table",
                      {
                        staticClass: "common-el-table",
                        attrs: { data: _vm.historyTbaleList, stripe: "" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "productMngCode",
                            label: "商品信息",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.orderDetailEventRes,
                                  function (
                                    item_orderDetailEventRes,
                                    index_orderDetailEventRes
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index_orderDetailEventRes,
                                        staticClass: "mt-10",
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item_orderDetailEventRes.productName
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "f-14 f-grey" },
                                          [
                                            _vm._v(
                                              " 品牌：" +
                                                _vm._s(
                                                  item_orderDetailEventRes.productBrand
                                                ) +
                                                "\n                    型号：" +
                                                _vm._s(
                                                  item_orderDetailEventRes.productModel
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                )
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "eventTypeName",
                            label: "事件类型",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "备注" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.detailLogs,
                                  function (item_detailLogs, index_detailLogs) {
                                    return _c(
                                      "div",
                                      { key: index_detailLogs },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "intro-text" },
                                          [
                                            _vm._v(
                                              "\n                    【" +
                                                _vm._s(
                                                  item_detailLogs.operator
                                                ) +
                                                "】：" +
                                                _vm._s(item_detailLogs.remark)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                )
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "eventStatusName",
                            label: "处理状态",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "organizer",
                            label: "提交人",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "processor",
                            label: "处理人",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "originalTime",
                            label: "提交时间",
                            width: "150px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "processTime",
                            label: "处理时间",
                            width: "150px",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }