"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250213160419/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// import { MessageBox, Message } from 'element-ui'

// create an axios instance
var serviceboss = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API_BOSS,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
});

// request interceptor
serviceboss.interceptors.request.use(function (config) {
  // do something before request is sent

  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token'] = (0, _auth.getToken)();
  }
  // CSRF
  if (_store.default.getters.csrf) {
    config.headers['hx-csrf'] = _store.default.getters.csrf.subcode;
    config.headers['hx-sessionid'] = _store.default.getters.csrf.sessionid;
  }
  config.headers['platform'] = 1;
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
serviceboss.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0) {
    _elementUi.Message.closeAll();
    (0, _elementUi.Message)({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 401) {
      _elementUi.Message.closeAll();
      (0, _elementUi.Message)({
        message: '您已经登出，需要再次登录',
        type: 'error',
        duration: 3 * 1000,
        onClose: function onClose() {
          location.reload();
          // this.$router.push(`/login`)
        }
      });
    }
    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  _elementUi.Message.closeAll();
  console.log('err' + error); // for debug
  (0, _elementUi.Message)({
    message: error.msg || '系统连接超时',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(new Error(error.msg || '系统连接超时'));
});
var _default = exports.default = serviceboss;