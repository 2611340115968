"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20241211163638/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getLanguage = getLanguage;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _objectSpread2 = _interopRequireDefault(require("/home/devops/repos/1_20241211163638/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _es = _interopRequireDefault(require("element-ui/lib/locale/lang/es"));
var _ja = _interopRequireDefault(require("element-ui/lib/locale/lang/ja"));
var _en2 = _interopRequireDefault(require("./en"));
var _zh = _interopRequireDefault(require("./zh"));
var _es2 = _interopRequireDefault(require("./es"));
var _ja2 = _interopRequireDefault(require("./ja"));
// element-ui lang
// element-ui lang
// element-ui lang
// element-ui lang

_vue.default.use(_vueI18n.default);
var messages = {
  en: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _en2.default), _en.default),
  zh: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _zh.default), _zhCN.default),
  es: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _es2.default), _es.default),
  ja: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _ja2.default), _ja.default)
};
function getLanguage() {
  var chooseLanguage = _jsCookie.default.get('language');
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en';
}
var i18n = new _vueI18n.default({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages
});
var _default = exports.default = i18n;